import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Dancing tree fractal with React",
  "description": "",
  "date": "2016-12-13T08:00:00.000Z",
  "published": "2016-12-13T08:00:00.000Z",
  "image": ""
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`That damn `}<a parentName="p" {...{
        "href": "https://swizec.com/blog/fractals-react/swizec/7233"
      }}>{`Pythagorean fractal`}</a>{` from last month wouldn't leave me alone, so I fixed it. Timing may or may not coincide with a commenter giving a solution to the wonky triangle problem.`}</p>
    <p><img parentName="p" {...{
        "src": "http://swizec.com/blog/wp-content/uploads/2016/12/dancing-tree.gif",
        "alt": "dancing-tree"
      }}></img></p>
    <p><a parentName="p" {...{
        "href": "https://github.com/Swizec/react-fractals"
      }}>{`Here’s the code on Github`}</a>{`. For the story and explanation, keep reading. :)`}</p>
    <p>{`It took `}<em parentName="p">{`somebody doing the math instead of me`}</em>{` to kick my arse into gear. Here's Vinicius Ribeiro schooling me on high school trigonometry:`}</p>
    <blockquote>
      <p parentName="blockquote">{`You are not applying the Law of Sines correctly. The variable 'd' is the diameter of the triangle's circumcircle, not its perimeter.`}</p>
      <p parentName="blockquote">{`Tinkering with your code on github, I've managed to accomplish what you were trying to do by doing the following calculations:`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const currentH = 0.2 * w,
  nextLeft = Math.sqrt(currentH * currentH + 0.7 * w * 0.7 * w),
  nextRight = Math.sqrt(currentH * currentH + 0.3 * w * 0.3 * w),
  A = Math.deg(Math.atan(currentH / (0.3 * w))),
  B = Math.deg(Math.atan(currentH / (0.7 * w)));
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`The height of the inner triangle is a fraction of the current 'w'. By doing that, we can infer nextLeft and nextRight using the Pythagorean theorem. The angles can then be calculated using the inverse tangent (atan) and the triangle height.`}</p>
      <p parentName="blockquote">{`Hope this helps!`}</p>
    </blockquote>
    <p><strong parentName="p">{`Help it did!`}</strong>{` Thanks, Vinicius.`}</p>
    <h2 {...{
      "id": "how-you-too-can-build-a-dancing-tree-fractal"
    }}>{`How you too can build a dancing tree fractal`}</h2>
    <p>{`Equipped with basic trigonometry, you need 3 ingredients to build a dancing tree:`}</p>
    <ul>
      <li parentName="ul">{`a recursive `}<inlineCode parentName="li">{`<Pythagoras>`}</inlineCode>{` component`}</li>
      <li parentName="ul">{`a mousemove listener`}</li>
      <li parentName="ul">{`a memoized next-step-props calculation function`}</li>
    </ul>
    <p>{`We'll use the `}<inlineCode parentName="p">{`<Pythagoras>`}</inlineCode>{` component from November, add a D3 mouse listener, and put Vinicus's math with some tweaks into a memoized function. We need D3 because its mouse listeners automatically calculate mouse position relative to SVG coordinates, and `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Memoization"
      }}>{`memoization`}</a>{` helps us keep our code faster.`}</p>
    <p>{`The improved `}<inlineCode parentName="p">{`<Pythagoras>`}</inlineCode>{` component takes a few more arguments than before, and it uses a function to calculate future props. Like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const Pythagoras = ({ w,x, y, heightFactor, lean, left, right, lvl, maxlvl }) => {
    if (lvl >= maxlvl || w < 1) {
        return null;
    }

    const { nextRight, nextLeft, A, B } = memoizedCalc({
        w: w,
        heightFactor: heightFactor,
        lean: lean
    });

    let rotate = '';

    if (left) {
        rotate = \`rotate(\${-A} 0 \${w})\`;
    }else if (right) {
        rotate = \`rotate(\${B} \${w} \${w})\`;
    }

    return (
        <g transform={\`translate(\${x} \${y})="" \${rotate}\`}="">
            <rect width={w} height={w} x={0} y={0} style="{{fill:" interpolateviridis(lvl="" maxlvl)}}="">

            <pythagoras w={nextLeft} x={0} y={-nextLeft} lvl={lvl+1} maxlvl={maxlvl} heightfactor={heightFactor} lean={lean} left="">

            <pythagoras w={nextRight} x={w-nextRight} y={-nextRight} lvl={lvl+1} maxlvl={maxlvl} heightfactor={heightFactor} lean={lean} right="">

        </pythagoras></pythagoras></rect></g>
    );
};
`}</code></pre>
    <p>{`We break recursion whenever we try to draw an invisible square or have reached too deep into the tree. Then we:`}</p>
    <ul>
      <li parentName="ul">{`use `}<inlineCode parentName="li">{`memoizedCalc`}</inlineCode>{` to do the mathematics`}</li>
      <li parentName="ul">{`define different `}<inlineCode parentName="li">{`rotate()`}</inlineCode>{` transforms for the `}<inlineCode parentName="li">{`left`}</inlineCode>{` and `}<inlineCode parentName="li">{`right`}</inlineCode>{` branches`}</li>
      <li parentName="ul">{`and return an SVG `}<inlineCode parentName="li">{`<rect>`}</inlineCode>{` for the current rectangle, and two `}<inlineCode parentName="li">{`<Pythagoras>`}</inlineCode>{` elements for each branch.`}</li>
    </ul>
    <p>{`Most of this code deals with passing arguments onwards to children. It’s not the most elegant approach, but it works. The rest is about positioning branches so corners match up.`}</p>
    <p><img parentName="p" {...{
        "src": "http://swizec.com/blog/wp-content/uploads/2016/12/corners-match-up.png",
        "alt": "corners-match-up"
      }}></img></p>
    <h2 {...{
      "id": "the-maths"
    }}>{`The maths`}</h2>
    <p>{`I don't `}<em parentName="p">{`really`}</em>{` understand this math, but I sort of know where it's coming from. It's the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Law_of_sines"
      }}>{`sine law`}</a>{` applied correctly. You know, the part I failed at miserably last time ?`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const memoizedCalc = (function () {
  const memo = {};

  const key = ({ w, heightFactor, lean }) => [w, heightFactor, lean].join("-");

  return (args) => {
    const memoKey = key(args);

    if (memo[memoKey]) {
      return memo[memoKey];
    } else {
      const { w, heightFactor, lean } = args;

      const trigH = heightFactor * w;

      const result = {
        nextRight: Math.sqrt(trigH ** 2 + (w * (0.5 + lean)) ** 2),
        nextLeft: Math.sqrt(trigH ** 2 + (w * (0.5 - lean)) ** 2),
        A: Math.deg(Math.atan(trigH / ((0.5 - lean) * w))),
        B: Math.deg(Math.atan(trigH / ((0.5 + lean) * w))),
      };

      memo[memoKey] = result;
      return result;
    }
  };
})();
`}</code></pre>
    <p>{`We added to Vinicius's maths a dynamic `}<inlineCode parentName="p">{`heightFactor`}</inlineCode>{` and `}<inlineCode parentName="p">{`lean`}</inlineCode>{` adjustment. We'll control those with mouse movement.`}</p>
    <p>{`To improve performance, maybe, our `}<inlineCode parentName="p">{`memoizedCalc`}</inlineCode>{` function has an internal data store that maintains a hash of every argument tuple and its result. This lets us avoid computation and read from memory instead.`}</p>
    <p>{`At 11 levels of depth, `}<inlineCode parentName="p">{`memoizedCalc`}</inlineCode>{` gets called 2,048 times and only returns 11 different results. You can't find a better candidate for memoization.`}</p>
    <p>{`Of course, a benchmark would be great here. Maybe `}<inlineCode parentName="p">{`sqrt`}</inlineCode>{`, `}<inlineCode parentName="p">{`atan`}</inlineCode>{`, and `}<inlineCode parentName="p">{`**`}</inlineCode>{` aren't `}<em parentName="p">{`that`}</em>{` slow, and our real bottleneck is redrawing all those nodes on every mouse move. `}<em parentName="p">{`Hint: it totally is.`}</em></p>
    <p>{`Now that I spell it out… what the hell was I thinking? I'm impressed it works as well as it does.`}</p>
    <h2 {...{
      "id": "the-mouse-listener"
    }}>{`The mouse listener`}</h2>
    <p>{`Inside `}<inlineCode parentName="p">{`App.js`}</inlineCode>{`, we add a mouse event listener. We use D3's because it gives us the SVG-relative position calculation out of the box. With React’s, we'd have to do the hard work ourselves.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// App.js
state = {
        currentMax: 0,
        baseW: 80,
        heightFactor: 0,
        lean: 0
    };

componentDidMount() {
    d3select(this.refs.svg)
       .on("mousemove", this.onMouseMove.bind(this));
}

onMouseMove(event) {
    const [x, y] = d3mouse(this.refs.svg),

    scaleFactor = scaleLinear().domain([this.svg.height, 0])
                                                         .range([0, .8]),

    scaleLean = scaleLinear().domain([0, this.svg.width/2, this.svg.width])
                                                     .range([.5, 0, -.5]);

    this.setState({
        heightFactor: scaleFactor(y),
        lean: scaleLean(x)
    });
}

// ...

render() {
    // ...
    <svg ref="svg"> //...
    <pythagoras w={this.state.baseW} h={this.state.baseW} heightfactor={this.state.heightFactor} lean={this.state.lean} x={this.svg.width/2-40} y={this.svg.height-this.state.baseW} lvl={0} maxlvl="{this.state.currentMax}/">
}
</pythagoras></svg>
`}</code></pre>
    <p>{`A couple of things happen here:`}</p>
    <ul>
      <li parentName="ul">{`we set initial `}<inlineCode parentName="li">{`lean`}</inlineCode>{` and `}<inlineCode parentName="li">{`heightFactor`}</inlineCode>{` to `}<inlineCode parentName="li">{`0`}</inlineCode></li>
      <li parentName="ul">{`in `}<inlineCode parentName="li">{`componentDidMount`}</inlineCode>{`, we use `}<inlineCode parentName="li">{`d3.select`}</inlineCode>{` and `}<inlineCode parentName="li">{`.on`}</inlineCode>{` to add a mouse listener`}</li>
      <li parentName="ul">{`we define an `}<inlineCode parentName="li">{`onMouseMove`}</inlineCode>{` method as the listener`}</li>
      <li parentName="ul">{`we render the first `}<inlineCode parentName="li">{`<Pythagoras>`}</inlineCode>{` using values from `}<inlineCode parentName="li">{`state`}</inlineCode></li>
    </ul>
    <p>{`The `}<inlineCode parentName="p">{`lean`}</inlineCode>{` parameter tells us which way the tree is leaning and by how much; the `}<inlineCode parentName="p">{`heightFactor`}</inlineCode>{` tells us how high those triangles should be. We control both with the mouse position.`}</p>
    <p>{`That happens in `}<inlineCode parentName="p">{`onMouseMove`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`onMouseMove(event) {
    const [x, y] = d3mouse(this.refs.svg),

    scaleFactor = scaleLinear().domain([this.svg.height, 0])
                                                         .range([0, .8]),

    scaleLean = scaleLinear().domain([0, this.svg.width/2, this.svg.width])
                                                     .range([.5, 0, -.5]);

    this.setState({
        heightFactor: scaleFactor(y),
        lean: scaleLean(x)
    });
}
`}</code></pre>
    <p><inlineCode parentName="p">{`d3mouse`}</inlineCode>{` – which is an imported `}<inlineCode parentName="p">{`mouse`}</inlineCode>{` function from `}<inlineCode parentName="p">{`d3-selection`}</inlineCode>{` – gives us cursor position relative to the SVG element. Two linear scales give us `}<inlineCode parentName="p">{`scaleFactor`}</inlineCode>{` and `}<inlineCode parentName="p">{`scalelean`}</inlineCode>{` values, which we put into component state.`}</p>
    <p>{`If you're not used to D3 scales, this reads as:`}</p>
    <ul>
      <li parentName="ul">{`map vertical coordinates between `}<inlineCode parentName="li">{`height`}</inlineCode>{` and `}<inlineCode parentName="li">{`0`}</inlineCode>{` evenly to somewhere between `}<inlineCode parentName="li">{`0`}</inlineCode>{` and `}<inlineCode parentName="li">{`.8`}</inlineCode></li>
      <li parentName="ul">{`map horizontal coordinates between `}<inlineCode parentName="li">{`0`}</inlineCode>{` and `}<inlineCode parentName="li">{`width/2`}</inlineCode>{` evenly to somewhere between `}<inlineCode parentName="li">{`.5`}</inlineCode>{` and `}<inlineCode parentName="li">{`0`}</inlineCode>{`, and coordinates between `}<inlineCode parentName="li">{`width/2`}</inlineCode>{` and `}<inlineCode parentName="li">{`width`}</inlineCode>{` to `}<inlineCode parentName="li">{`0`}</inlineCode>{` and `}<inlineCode parentName="li">{`-.5`}</inlineCode></li>
    </ul>
    <p>{`When we feed a change to `}<inlineCode parentName="p">{`this.setState`}</inlineCode>{`, it triggers a re-render of the entire tree, our `}<inlineCode parentName="p">{`memoizedCalc`}</inlineCode>{` function returns new values, and the final result is a dancing tree.`}</p>
    <p><img parentName="p" {...{
        "src": "http://swizec.com/blog/wp-content/uploads/2016/12/dancing-tree.gif",
        "alt": "dancing-tree"
      }}></img></p>
    <p>{`Beautious. ?`}</p>
    <p>{`PS: last time, I mentioned that recursion stops working when you make a React build optimized for production. That doesn't happen. I don't know what was wrong with the specific case where I saw that behavior. ¯`}{`\\`}<em parentName="p">{`(ツ)`}</em>{`/¯`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      